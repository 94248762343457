@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Varela+Round&display=swap');
@font-face{
    font-family: NotoSans;
    src: url('/public/fonts/NotoSans-Regular.woff');
    font-weight:400;
    unicode-range: U+AC00-D7A3;
    font-display:swap;
} 
@font-face {
    font-family: NotoSans;
    src: url('/public/fonts/NotoSans-Medium.woff') format('woff');
    font-weight:500;
    unicode-range: U+AC00-D7A3;
    font-display:swap;
} 
@font-face {
    font-family: NotoSans;
    src: url('/public/fonts/NotoSans-Bold.woff') format('woff');
    font-weight:600;
    unicode-range: U+AC00-D7A3;
    font-display:swap;
} 

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family:inherit;
}

body {
    margin: 0;
    font-family:  "NotoSans", "Roboto",sans-serif;

    background-color: #f0f1f4;
    transition: background-color 0.8s;

    overflow:hidden;

    touch-action: pan-y;
}

body.no-touch-screen{
    overflow-y:auto;
}

body.dark-mode {
    background-color: #000000;
}

body.final-view{
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

::selection {
    color: #f0f1f4;
    background: #000;
}

.dark-mode ::selection {
    background: #f0f1f4;
    color: #000;
}

button {
    color: #000;
}

a, button, label, input{
    -webkit-tap-highlight-color: transparent;
}

input{
    -webkit-appearance: none;
}

.style-wrap {
    -webkit-transform: translateZ(0);
}

br.no-mobile {
    display: block;
}
br.no-pc {
    display: none;
}

.no-transition{
    transition-delay:0s !important;
    transition-duration:0s  !important;
    transition-property:none  !important;
}

@media screen and (max-width:1055px){
    br.no-mobile {
        display: none;
    }
    br.no-pc {
        display: block;
    }
}

.etc-detail {
    position: absolute;
    width: 100%;
    padding: 0;
    bottom: 0;

    transform: translate(0, 100%);

    opacity: 0;
    transition: opacity 0.3s;

    &.show {
        opacity: 1;
    }

    @media screen and (max-width: 1055px) {
        padding: 0;
    }
}
